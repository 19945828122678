import { Injectable, EventEmitter } from '@angular/core';

@Injectable()
export class LocalStorageService {
    langChanged: EventEmitter<void> = new EventEmitter();

    getLanguage() {
        return localStorage.getItem('lang');
    }

    setLanguage(lang: string) {
        localStorage.setItem('lang', lang);
        this.langChanged.emit();
    }
}
