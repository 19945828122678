import { KeycloakConfig } from 'keycloak-angular';

let keycloakConfig: KeycloakConfig = {
    url: 'https://keycloak-dev.atoscamp.net/auth',
    realm: 'master',
    clientId: 'atoscamp-qualif',
};

export const environment = {
    production: false,
    keycloak: keycloakConfig,
    appUrl: 'https://qualif.atoscamp.net/',
};
