import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('./modules/showcase/showcase.module').then(m => m.ShowcaseModule),
    },
    {
        path: 'administration',
        loadChildren: () =>
            import('./modules/user-administration/user-administration.module').then(m => m.UserAdministrationModule),
    },
    {
        path: 'administration',
        loadChildren: () =>
            import('./modules/user-administration/user-administration.module').then(m => m.UserAdministrationModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes , {scrollPositionRestoration: 'top'})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
