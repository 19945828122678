import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';

@Injectable()
export class UserFacade {
    constructor(private keycloakService: KeycloakService) {}

    getConnectedUserRoles() {
        if (!this.keycloakService.getKeycloakInstance().tokenParsed) {
            return [];
        }
        return this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles;
    }

    isAdmin() {
        return this.getConnectedUserRoles().includes('admin');
    }
}
