import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IsAuthGuard } from './guards/is-auth.guard';
import { NavComponent } from './components/nav/nav.component';
import { FooterComponent } from './components/footer/footer.component';
import { KeycloakApiService } from './services/keycloak-api.service';
import { RouterModule } from '@angular/router';
import { MatMenuModule } from '@angular/material/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from './services/localStorage.service';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { MaterialModule } from '../material.module';

@NgModule({
    imports: [
        MatMenuModule,
        CommonModule,
        RouterModule,
        ReactiveFormsModule,
        MaterialModule,
        TranslateModule.forChild({}),
        ToastrModule.forRoot(),
    ],
    declarations: [NavComponent, FooterComponent],
    providers: [IsAuthGuard, KeycloakApiService, LocalStorageService],
    exports: [NavComponent, FooterComponent],
})
export class CoreModule {}
