import { Component, OnInit } from '@angular/core';
import { version } from '../../../../../package.json';
import * as _offers from '../../../../assets/datas/offers.json';
import { Offer } from 'src/app/core/models/offer.model';
import { Router } from '@angular/router';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
    public appVersion: string = version;
    visibleBg = false;

    constructor(protected router: Router,) {}
    offers: Offer[] = (_offers as any).default;

    ngOnInit() {
        this.router.events.subscribe(e => {
            if (this.router.url !== '/') {
                this.visibleBg = true;
            } else {
                this.visibleBg = false;
            }
        });
    }

        // Scroll to clicked element in nav
    scrollTo(target) {
        const element = document.getElementById(target);
        const xPosition = element.offsetTop;
        window.scrollTo({top: xPosition-65, behavior: 'smooth'});
    }

    redirectTo(target){
        if(this.visibleBg=== true){
            this.router.navigate(['/']).then(()=> { 
            setTimeout( () => { this.scrollTo(target)}, 100)}); // setTimeout to wait for the page to load before scrolling 
        }
        else if (this.visibleBg=== false){
            this.scrollTo(target);
        }      
    }
     
}
