import { Component } from '@angular/core';
import { LocalStorageService } from './core/services/localStorage.service';
import { TranslateService } from '@ngx-translate/core';
import { HostListener } from '@angular/core';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    title = 'portal-project';

    constructor(private localStorageService: LocalStorageService, private translateService: TranslateService) {
        this.translateService.setDefaultLang('en');
        let lang = this.localStorageService.getLanguage();
        if (lang) {
            this.localStorageService.setLanguage(lang);
            this.translateService.use(lang);
        } else {
            lang = navigator.language.split('-')[0]; // De la forme 'fr-FR'
            lang = lang === 'fr' || lang === 'en' ? lang : 'en';
            this.localStorageService.setLanguage(lang);
            this.translateService.use(lang);
        }

        this.localStorageService.langChanged.subscribe(() =>
            this.translateService.use(this.localStorageService.getLanguage())
        );
        
    }
    scrollTop: boolean;

        // Scroll To Top button animation
        @HostListener('document:scroll')
        scrollToTopButton(){
            const scrollTop = document.documentElement.scrollTop; 
            if( scrollTop > 0){
                this.scrollTop= true;            
            }else{
                this.scrollTop= false;
            }
        }
    
        // Scroll to top function
        scrollToTop(){
            const c = document.documentElement.scrollTop ;
            if (c > 0) {
                window.scrollTo({top: 0, behavior: 'smooth'});
              }
        }
}
