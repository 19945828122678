import { KeycloakService } from 'keycloak-angular';

import { environment } from '../../../environments/environment';

export function initializer(keycloak: KeycloakService): () => Promise<any> {
    return (): Promise<any> => {
        return new Promise(async (resolve, reject) => {
            try {
                await keycloak.init({
                    config: environment.keycloak,
                    initOptions: {
                        // Wich will require a login as soon as keycloak is loaded
                        onLoad: 'check-sso',
                        checkLoginIframe: false,
                    },
                    enableBearerInterceptor: true,
                    bearerExcludedUrls: ['/home'],
                });
                resolve();
            } catch (error) {
                reject(error);
            }
        });
    };
}
