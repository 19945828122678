import { Injectable } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { User } from '../models/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { HttpParams } from '@angular/common/http';
import { RequiredAction } from '../models/keycloak.model';

@Injectable()
export class KeycloakApiService {
    constructor(private keycloakAngular: KeycloakService, private http: HttpClient) {}

    private usersUrl = environment.keycloak.url + '/admin/realms/master/users';
    private groupsUrl = environment.keycloak.url + '/admin/realms/master/groups';
    private requireActionsUrl = environment.keycloak.url + '/admin/realms/master/authentication';

    httpOptions = {
        headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };

    login() {
        this.keycloakAngular.login({ redirectUri: '/tools' });
    }

    logout() {
        this.keycloakAngular.logout();
    }

    // get users
    getUsers() {
        return this.http.get(this.usersUrl, { params: new HttpParams({ fromString: 'max=100000' }) });
    }

    // get user according to username
    getID(username: string): Observable<any> {
        return this.http.get<any>(this.usersUrl, { params: new HttpParams({ fromString: `username=${username}` }) });
    }

    /**
     * @returns an array of the user's groups
     */
    getUserGroups(userId: string): Observable<{ id: string; name: string; path: string }[]> {
        return this.http.get<{ id: string; name: string; path: string }[]>(`${this.usersUrl}/${userId}/groups`);
    }

    // create user
    createUser(user: User): Observable<any> {
        return this.http.post<User>(this.usersUrl, user, this.httpOptions);
    }

    updateUser(user: Partial<User>): Observable<any> {
        return this.http.put<any>(`${this.usersUrl}/${user.id}`, user);
    }

    // Delete user
    deleteUser(id: string) {
        return this.http.delete(environment.keycloak.url + '/admin/realms/master/users/' + id, this.httpOptions);
    }

    // Set password
    setPassword(id: any, body: any): Observable<any> {
        return this.http.put<any>(
            environment.keycloak.url + '/admin/realms/master/users/' + id + '/reset-password',
            body,
            this.httpOptions
        );
    }

    // Groups management
    getAllGroups(): Observable<any> {
        return this.http.get(this.groupsUrl);
    }

    addUserToGroup(userId: string, groupId: string) {
        return this.http.put(`${this.usersUrl}/${userId}/groups/${groupId}`, { realm: 'master', userId, groupId });
    }

    removeUserFromGroup(userId: string, groupId: string) {
        return this.http.delete(`${this.usersUrl}/${userId}/groups/${groupId}`);
    }

    getAllRequiredActions(): Observable<RequiredAction[]> {
        return this.http.get<RequiredAction[]>(`${this.requireActionsUrl}/required-actions`);
    }
}
