import { Component, HostListener, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { environment } from '../../../../environments/environment';
import { KeycloakApiService } from '../../services/keycloak-api.service';
import { UserFacade } from '../../../facades/user.facade';
import { LocalStorageService } from '../../services/localStorage.service';
import * as _offers from '../../../../assets/datas/offers.json';
import { Offer } from 'src/app/core/models/offer.model';

@Component({
    selector: 'app-nav',
    templateUrl: './nav.component.html',
    styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnInit {
    appTitle = 'Atoscamp';
    keycloakIsLoggedIn: any;
    keycloakIsLoggedInValue: any;
    authenticated: boolean;
    lang = 'FR';
    visibleBg = false;
    openMenu = false;


    constructor(
        protected router: Router,
        protected keycloakAngular: KeycloakService,
        private keycloakApi: KeycloakApiService,
        private userFacade: UserFacade,
        private localStorageService: LocalStorageService,
    ) {}

    offers: Offer[] = (_offers as any).default;
    ScreenHeight = screen.height;
    scrolled : boolean;

        
    ngOnInit() {
        this.isAuthenticated();
        this.lang = this.localStorageService.getLanguage();

        this.router.events.subscribe(e => {
            if (this.router.url !== '/') {
                this.visibleBg = true;
            } else {
                this.visibleBg = false;
            }
        });
    }

    // Scroll past header
    @HostListener('document:scroll')
    navScroll() {
        const scrollTop = document.documentElement.scrollTop;
        if(scrollTop > 0){
            this.scrolled= true
        }
        else{
            this.scrolled=false;
        }        
    }
    
    selectLang(lang) {
        this.localStorageService.setLanguage(lang);
    }

    login() {
        this.keycloakApi.login();
    }

    logout() {
        this.keycloakAngular.logout(`${environment.appUrl}`);
    }

    isAdmin() {
        return this.userFacade.isAdmin();
    }

    isAuthenticated() {
        this.keycloakIsLoggedIn = this.keycloakAngular.isLoggedIn();
        this.keycloakIsLoggedInValue = this.keycloakIsLoggedIn.__zone_symbol__value;

        if (this.keycloakIsLoggedInValue) {
            this.authenticated = true;
        } else {
            this.authenticated = false;
        }
    }

    changePassword() {
        window.open(
            `${environment.keycloak.url}/realms/master/account/#/security/signingin`,
            '_blank'
        );
    }

    // Scroll to clicked element in nav
    scrollTo(target) {
        const element = document.getElementById(target);
        const xPosition = element.offsetTop;
        window.scrollTo({top: xPosition-65, behavior: 'smooth'});
    }

    redirectTo(target){
        if(this.visibleBg=== true){
            this.router.navigate(['/']).then(()=> { 
            setTimeout( () => { this.scrollTo(target)}, 100)}); // setTimeout to wait for the page to load before scrolling
        }
        else if (this.visibleBg=== false){
            this.scrollTo(target);
        }      
    }
}
