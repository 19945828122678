<link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

<header class="header" [class.visibleBg]="visibleBg || scrolled" >

    <!--left-->
    <div class="left">
        <a  id="title-nav" [class.scrolled]="scrolled  || visibleBg" routerLink="/" class="clickable">{{ 'core.navbar.appTitle' | translate }}</a>
    </div>

    <!--middle -->  
    <div class="middle">
        <span   #menuTrigger="matMenuTrigger"
                (click)="menuTrigger.openMenu()"
                [matMenuTriggerFor]="offres"
                style="display:flex ; align-items: center; justify-content: center;" class="clickable" >  

            <a
            [class.scrolled]="scrolled  || visibleBg ">
                {{ 'core.navbar.offers' | translate }}
            </a>
            <mat-icon inline="true" class="offers-arrow" [class.scrolled]="scrolled  || visibleBg ">keyboard_arrow_down</mat-icon>
        </span>
         <!--Menu offres-->
        <mat-menu #offres="matMenu" [overlapTrigger]="false"   >
           <span (mouseleave)="menuTrigger.closeMenu()" >
                <div mat-menu-item  *ngFor=" let offer of offers"  routerLink="/offer/{{offer.id}}" >
                    <a class="offre">
                        {{offer.title}}
                    </a>
                </div>
            </span>
        </mat-menu>
       
        <a   (click)="redirectTo('accompagnement')" [class.scrolled]="scrolled  || visibleBg " >
            {{ 'core.navbar.accompagnement' | translate }}
        </a>

        <a  (click)="redirectTo('DADC')" [class.scrolled]="scrolled  || visibleBg " >
            {{ 'core.navbar.dadc' | translate }}
        </a>

        <a (click)="redirectTo('projets')" [class.scrolled]="scrolled  || visibleBg">
            {{ 'core.navbar.projects' | translate }}
        </a>

    </div>

    <!--right-->
    <div class="right">
        <a 
            [class.scrolled]="scrolled  || visibleBg"
            routerLinkActive="active"
            routerLink="/tools"
            [routerLinkActiveOptions]="{ exact: true }"
            *ngIf="authenticated"
        >
            {{ 'core.navbar.tools' | translate }}
        </a>
        <a routerLinkActive="active" routerLink="/administration/listUsers" *ngIf="isAdmin()" [class.scrolled]="scrolled  || visibleBg">
            {{ 'core.navbar.administration' | translate }}
        </a>
        <a  (click)="login()" *ngIf="!authenticated"  [class.scrolled]="scrolled  || visibleBg">
            {{ 'core.navbar.login' | translate }}
        </a>

        <a #createPlanmenuTrigger="matMenuTrigger" *ngIf="authenticated" [matMenuTriggerFor]="createPlan" [class.scrolled]="scrolled  || visibleBg">
            {{ 'core.navbar.account' | translate }}
        </a>
        <mat-menu #createPlan="matMenu">
            <div style="margin: 15px 25px;">
                <a (click)="changePassword()" style="color: var(--couleur-typo);">
                    {{ 'core.navbar.password' | translate }}
                </a>
            </div>
            <div style="margin: 15px 25px;">
                <a routerLinkActive="active" (click)="logout()"  style="color: var(--couleur-typo);" >
                    {{ 'core.navbar.logout' | translate }}
                </a>
            </div>
        </mat-menu>

        <!--language-->
        <mat-icon [matMenuTriggerFor]="menu" inline="true" [class.scrolled]="scrolled  || visibleBg" class="clickable" style="color: white; font-size: 2vw; margin-right: 2vw; ">language</mat-icon>
        <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="selectLang('fr')" style="color: var(--couleur-typo);" >
              <span>FR</span>
            </button>
            <button mat-menu-item (click)="selectLang('en')" style="color: var(--couleur-typo);" >
              <span>EN</span>
            </button>
        </mat-menu>
    </div>
</header>
