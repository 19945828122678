<div>
    <div class="row" id="footer">
        <!--Background icons-->
        <img class="bg-circles bg-circle1" src="../../../../../assets/img/bg-icons/carre_petit_rond.svg">
        <img class="bg-circles bg-circle2" src="../../../../../assets/img/bg-icons/carre_petit_rond.svg">
        <img class="bg-circles bg-circle3" src="../../../../../assets/img/bg-icons/carre_petit_rond.svg">


        <div id="left-side">
            <h2 style="margin-bottom: 40px;">{{ 'core.navbar.appTitle' | translate }}</h2>
            <div style="display: block;">
                <p style="font-weight: bold;">{{ 'core.navbar.offers' | translate }}</p>
                <p class="offre" *ngFor=" let offer of offers" [offer]="offer"
                routerLink="/offer/{{offer.id}}"> {{offer.title}}</p>
            </div>
            <div>
            <p style="font-weight: bold;" (click)="redirectTo('accompagnement')">{{ 'core.navbar.accompagnement' | translate }}</p>
            <p style="font-weight: bold; margin: 20px 0;" (click)="redirectTo('DADC')">{{ 'core.navbar.dadc' | translate }}</p>
            <p style="font-weight: bold;  margin: 20px 0;" (click)="redirectTo('projets')" >{{ 'core.navbar.projects' | translate }}</p>
            </div>
        </div>
        

        
        <div id="right-side">
            <div style="margin-bottom: 40px ;">
                <p>EKLA Business</p>
                <p>78 rue de la chaude rivière, 59800 Lille</p> 
                <p>France, EU</p>
                <a style="font-weight: bold; text-decoration: none;" href="mailto:alain.dhennin@atos.net" >{{ 'core.footer.contact' | translate }}</a>
            </div>
            <div>
                <p>Paris Business</p>
                <p>153 Avenue Jean Jaurès, 93300 Aubervilliers</p> 
                <p>France, EU</p>
                <a style="font-weight: bold;">{{ 'core.footer.contact' | translate }}</a>
            </div>
        </div>

        
        <div id="copyright">
            <p> {{ 'core.footer.slogan-footer' | translate }}<span style="color: grey;"> For internal use @Atos S E {{ appVersion }} all rights reserved.</span></p>
        </div>

    </div>
</div>